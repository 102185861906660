.btn-copy {
  background: white;
  position: absolute !important;
  right: 16px;
  top: 10px;

  &.right-bottom {
    bottom: 2.5em !important;
    right: 1.875em !important;
    top: auto !important;
  }
}
