.radio-group-block {
  display: flex;
  flex-wrap: wrap;

  .radio-group {
    display: flex;
    flex-wrap: wrap;
    column-gap: .5rem;
  }
}
