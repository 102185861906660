@use '../custom-variables/colors' as *;

.result-block-image {

  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  width: 48px;
  aspect-ratio: 1 / 1;
  background: $sasha-gray;

  i {
    font-size: 16px;
    color: $satan-black;
  }
}

.result-block-image-lg {
  @extend .result-block-image;
  min-width: 100px;
  width: 100px;

  i {
    font-size: 60px;
  }
}

.details-block {
  padding: 21px 23px !important;
}
