@use '../custom-variables/grid';

.customize-block {
  background: white;
  padding: 36px 39px;

  @media (max-width: grid.get-grid-bp('md')) {
    padding: 1.3rem;
  }
}
