@use './rating-stars';
@use './page-promo-card';
@use './tool-promo-card';
@use './tool-link-card';
@use './progress-bars';
@use './copy-btn';
@use './google-block';
@use './domain-block';
@use './result-block';
@use './tooltips';
@use './step-by-step-section';
@use './extra-service-card';
@use './elips';
@use './dot';
@use './promo-code-block';
@use './scroll-btn';
@use './compare-block';
@use './color-picker';
@use './customize-block';
@use './dynamic-input-group';
@use './radio-group-block';

.side-menu,
.tool-link-card-menu {

  .tool-link-card {
    max-width: unset;
  }
}

.raw-dig,
.raw-whois-block {
  display: none;
  background-color: white;
  font-size: .75rem;
  margin-bottom: 2em;
  padding: 1.5em;
  white-space: pre-line;
}
