@use '../custom-variables/colors' as *;

.dynamic-input-group {

  span {
    display: flex;
    background: $sasha-gray;
    border-radius: 50%;
    min-width: 47px;
    aspect-ratio: 1/1;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    margin-left: 28px;
    transition: 500ms;

    &:hover,
    &:active {
      background: $tool-promo-card-icon-bg-hover;
    }
  }
}
