@use './hyper-icons/hyper-icons';
@use './custom-bootstrap-variables';

@font-face {
  font-family: 'Noto Sans';
  font-weight: 600;
  src: local('Noto Sans'), url('../fonts/noto/NotoSans-SemiBold.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  font-weight: 500;
  src: local('Noto Sans'), url('../fonts/noto/NotoSans-Medium.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  font-weight: 400;
  src: local('Noto Sans'), url('../fonts/noto/NotoSans-Regular.woff2');
  font-display: swap;
}

@font-face {
  font-family: 'Noto Sans';
  font-weight: 300;
  src: local('Noto Sans'), url('../fonts/noto/NotoSans-Light.woff2');
  font-display: swap;
}

body {
  text-underline-offset: 6px;
  letter-spacing: -0.48px;
}

p {
  font-size: 20px;
  font-weight: 400;
}

h1,
h2,
h3 {
  font-weight: 600;
  letter-spacing: -0.88px;
}

.tools-label {
    margin-left: 0.45rem;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
}
