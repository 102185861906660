@use '../custom-variables/colors';
@use '../hyper-icons/icon-variables' as *;

@mixin promo-card-head {
  position: relative;

  .title {
    font-weight: 600;
    font-size: 26px;
    line-height: 31px;
    letter-spacing: -0.67px;

    margin-bottom: 14px;
  }

  .sub-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.51px;

    margin-bottom: 18px;

    color: colors.$cards-gray;
  }

  hr {
    @include hr;
    margin-top: unset;
    margin-bottom: unset;

    width: 100%;
  }

  .label {
    position: absolute;
    right: 0;
    top: 0;
  }
}

@mixin card-title {
  font-weight: 600;
  font-size: 26px;
  line-height: 33px;
  letter-spacing: -0.67px;

  margin-bottom: 13px;

  color: colors.$cards-black;
}

@mixin card-description {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.51px;

  color: colors.$cards-gray;
}

@mixin card-image {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 1.1em;

  * {
    font-size: 73px;
    width: 73px;
    height: 73px;
  }
}

@mixin card-price {
  .slot {
    color: colors.$cards-black;
    letter-spacing: -0.32px;
    font-weight: 600;
    font-size: 16px;
    line-height: 31px;
  }

  color: colors.$cards-black;
  font-weight: 600;
  font-size: 40px;
  line-height: 31px;

  margin-bottom: 51px;

  .watermark {
    color: colors.$cards-black;
    font-weight: 600;
    font-size: 20px;
    line-height: 31px;
  }
}

@mixin card-benefits {
  ul.benefits {
    margin: 0;
    padding: 0;

    margin-left: 8px;

    li.benefit {
      &::marker {
        content: "#{$hypericon-angle-li-right}";
        font-family: 'hyper-icons';
        font-size: 16px;
        color: colors.$red;
      }

      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: -0.6px;

      color: colors.$cards-black;

      white-space: nowrap;

      padding-left: 4px;
    }
  }

  .benefits:not(ul) {
    max-height: 104px;

    margin-top: 25px;

    .benefit:not(li) {
      color: colors.$cards-gray;

      margin-left: 9px;

      i {
        margin-right: 5px;
      }

      p {
        font-weight: 400;
        font-size: 16px;
        line-height: 25px;
        letter-spacing: -0.6px;
      }
    }
  }

}

@mixin hr {
  margin: 0;

  margin-top: 17px;
  margin-bottom: 25px;

  opacity: unset;

  border: 0;
  color: colors.$hr-gray;
  border-top: 2px solid;
  border-radius: 10px;
}

@mixin card-shadow {
  box-shadow: -1.6px 1.2px 54.7px colors.$card-shadow;

  &:hover {
    box-shadow: 0px 4px 52px colors.$card-shadow-hovered;
  }
}
