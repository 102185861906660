@use '../custom-variables/colors' as *;
@use '../custom-variables/grid';
@use './cards';

.tool-link-card {
  $transition-time: 500ms;
  @include cards.card-shadow;

  display: flex;
  align-items: center;

  border-radius: 25px;
  background: $page-promo-card-bg;

  text-decoration: none;
  color: inherit;
  text-align: center;

  padding-block: 22px;
  padding-inline: 20px;

  transition: $transition-time;

  max-width: 263px;

  .card-image-block {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100px;
    max-width: 60px;
    aspect-ratio: 1/1;

    border-radius: 50%;
    background: $tool-promo-card-icon-bg;
    transition: $transition-time;

    $img-size: 38px;

    margin-right: 10px;

    .image,
    img,
    svg {
      width: $img-size;
      height: $img-size;
    }

    i {
      font-size: $img-size;
    }
  }

  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;
    text-align: start;

    .card-title {
      font-size: 18px;
    }

    .card-btn-block {
      text-decoration: underline;
    }
  }

  &:hover,
  &:active {
    .card-image-block {
      background: $tool-promo-card-icon-bg-hover;
    }
  }
}
