@use '../custom-variables/colors' as *;

.faqs-section {

  .title-section {
    margin-bottom: 4.5em;
    text-align: center;

    .title {
      font-weight: 600;
      line-height: 55px;

      letter-spacing: -0.88px;
    }

    .undertitle-text {
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;


      margin: 0;
    }
  }

  .accordion {
    margin-top: 45px;

    --bs-accordion-btn-icon-width: 35px;

    .accordion-item {
      color: $faq-section-question-text-color;

      border: 0 solid transparent !important;
      border-radius: 20px;
      box-shadow: -1.6px 1.2px 21px #0000001a;

      max-width: 640px;

      .accordion-header {
        &.question {
          font-weight: 600;
          font-size: 24px;
          line-height: 2rem;


          &.accordion-button {
            background: $faq-section-question-bg !important;

            border-radius: 20px;

            padding-left: 45px;
            padding-right: 32px;

            padding-block: 13px;

            cursor: pointer;

            &.collapsed {
              border-radius: 20px;
            }

            &:not(.collapsed) {
              box-shadow: unset;
              color: $faq-section-question-text-color;

              &::after {
                background-image: var(--bs-accordion-btn-icon);
              }
            }
          }
        }
      }

      .accordion-collapse {
        .accordion-body {
          &.answer {
            padding-inline: 45px;
            padding-bottom: 45px;
            padding-top: 6px;

            & * {
              margin: 0;

              font-size: 1rem;
              font-weight: 400;
              line-height: 1.3;


              a {
                color: inherit;
              }
            }
          }
        }
      }
    }
  }
}
