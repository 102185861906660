$icomoon-font-family: "hyper-icons" !default;
$icomoon-font-path: "../fonts/icons/hyper" !default;

$hypericon-1c-bitrix: "\e9a0";
$hypericon-uniE9CF: "\e9cf";
$hypericon-T: "\e9d0";
$hypericon-a-z-icon: "\e922";
$hypericon-adv-cash: "\e90b";
$hypericon-africa-icon: "\e90c";
$hypericon-ai-brain: "\e907";
$hypericon-amiro: "\e995";
$hypericon-angle-down-solid: "\e942";
$hypericon-angle-li-right: "\e96c";
$hypericon-angle-right-solid: "\e926";
$hypericon-angle-up-solid: "\e952";
$hypericon-apple-pay-logo: "\e90d";
$hypericon-arrow: "\e96a";
$hypericon-arrow-down: "\e90e";
$hypericon-arrow-down-solid: "\e950";
$hypericon-arrow-left: "\e90f";
$hypericon-arrow-right: "\e910";
$hypericon-arrow-up: "\e911";
$hypericon-asia-icon: "\e912";
$hypericon-at-solid: "\e95a";
$hypericon-atlas-solid: "\e94b";
$hypericon-bitcoin-logo: "\e913";
$hypericon-browser: "\e914";
$hypericon-building-solid: "\e958";
$hypericon-cake: "\e919";
$hypericon-calendar: "\e9b3";
$hypericon-calendar-alt: "\e95c";
$hypericon-calendar-tiny: "\e901";
$hypericon-card: "\e915";
$hypericon-card-2: "\e9d1";
$hypericon-card-icon-1: "\e905";
$hypericon-card-icon-infinity: "\e916";
$hypericon-cart: "\e917";
$hypericon-cart-alt: "\e98c";
$hypericon-cdn: "\e97f";
$hypericon-centos: "\e98b";
$hypericon-cert: "\e918";
$hypericon-cert-alt: "\e91a";
$hypericon-chart: "\e990";
$hypericon-chat-gpt: "\e91b";
$hypericon-cheap-domains: "\e91c";
$hypericon-check-solid: "\e945";
$hypericon-check-solid-alt: "\e9b7";
$hypericon-check-window: "\e900";
$hypericon-cloud-traffic: "\e989";
$hypericon-email: "\e9d2";
$hypericon-mobile: "\e9d3";
$hypericon-qr: "\e9d4";
$hypericon-sms: "\e9d5";
$hypericon-telegram: "\e9d6";
$hypericon-twitter-x: "\e9d7";
$hypericon-vCard: "\e9d8";
$hypericon-wifi: "\e9d9";
$hypericon-uniE9DA: "\e9da";
$hypericon-cloud-upload: "\e98a";
$hypericon-cms: "\e91d";
$hypericon-code: "\e91e";
$hypericon-construct: "\e91f";
$hypericon-copy-btn: "\e9b6";
$hypericon-cpanel: "\e988";
$hypericon-cs-cart: "\e996";
$hypericon-cyrcle-cake: "\e920";
$hypericon-dashboard: "\e921";
$hypericon-db: "\e991";
$hypericon-db-sync: "\e923";
$hypericon-debian: "\e987";
$hypericon-destination: "\e909";
$hypericon-device-hdd: "\e9b0";
$hypericon-device-ssd: "\e9b1";
$hypericon-devices: "\e962";
$hypericon-diafan: "\e997";
$hypericon-dice-solid: "\e924";
$hypericon-digital-security: "\e903";
$hypericon-direction-field: "\e925";
$hypericon-dle: "\e998";
$hypericon-dns-circle: "\e90a";
$hypericon-double-stack: "\e927";
$hypericon-double-stack-tiny: "\e904";
$hypericon-drupal: "\e92e";
$hypericon-dv-ssl: "\e9a6";
$hypericon-education-cap: "\e992";
$hypericon-education-monitor: "\e928";
$hypericon-eu-domain: "\e929";
$hypericon-eu-domains: "\e92a";
$hypericon-eu-emblem: "\e92b";
$hypericon-ev-ssl: "\e9a7";
$hypericon-event-ring: "\e9b5";
$hypericon-exclamation: "\e906";
$hypericon-eye-slash-solid: "\e94d";
$hypericon-eye-solid: "\e94e";
$hypericon-facebook: "\e966";
$hypericon-facebook-square-brands: "\e957";
$hypericon-file-checker: "\e9cc";
$hypericon-flag-alt: "\e92c";
$hypericon-flag-solid: "\e954";
$hypericon-foreign-domains: "\e92d";
$hypericon-free: "\e963";
$hypericon-glasses: "\e92f";
$hypericon-go-arrow: "\e930";
$hypericon-google: "\e908";
$hypericon-google-pay-logo: "\e931";
$hypericon-hand: "\e932";
$hypericon-hard-drive: "\e933";
$hypericon-headphones: "\e934";
$hypericon-home-solid: "\e956";
$hypericon-hostcms: "\e999";
$hypericon-icon: "\e935";
$hypericon-infinit-in-circle: "\e936";
$hypericon-infinity: "\e937";
$hypericon-info-circle: "\e9a8";
$hypericon-info-solid: "\e955";
$hypericon-instagram: "\e967";
$hypericon-ip-address: "\e902";
$hypericon-joomla: "\e938";
$hypericon-key: "\e939";
$hypericon-key-solid: "\e959";
$hypericon-lego: "\e93a";
$hypericon-letter: "\e9a4";
$hypericon-link: "\e986";
$hypericon-loader: "\e985";
$hypericon-location: "\e93b";
$hypericon-location-arrow-solid: "\e941";
$hypericon-lock: "\e93c";
$hypericon-lock-open: "\e93d";
$hypericon-lock-rounded: "\e9a9";
$hypericon-magento: "\e93e";
$hypericon-map-point: "\e93f";
$hypericon-mastercard: "\e940";
$hypericon-mc-exchange: "\e9af";
$hypericon-minus-solid: "\e946";
$hypericon-modx: "\e943";
$hypericon-monitor: "\e944";
$hypericon-mono: "\e947";
$hypericon-moodle: "\e948";
$hypericon-motocms: "\e9a1";
$hypericon-netcat: "\e99a";
$hypericon-nvme: "\e9b2";
$hypericon-office: "\e993";
$hypericon-okaycms: "\e99b";
$hypericon-opencart: "\e949";
$hypericon-optical-disk: "\e984";
$hypericon-ov-ssl: "\e9aa";
$hypericon-p24-logo: "\e94a";
$hypericon-passport: "\e95d";
$hypericon-paypal-logo: "\e94c";
$hypericon-pen: "\e94f";
$hypericon-percent: "\e951";
$hypericon-perfecto-cms: "\e99c";
$hypericon-phone-solid: "\e953";
$hypericon-php: "\e95b";
$hypericon-phpshop: "\e99d";
$hypericon-plus-solid: "\e95e";
$hypericon-popular-domain: "\e95f";
$hypericon-prestashop: "\e960";
$hypericon-quotes: "\e9ad";
$hypericon-redirect: "\e9c9";
$hypericon-rubber: "\e961";
$hypericon-sale-window: "\e96b";
$hypericon-santafox: "\e9a2";
$hypericon-save: "\e964";
$hypericon-scales: "\e9cd";
$hypericon-scheme-distributed: "\e965";
$hypericon-scheme-key: "\e968";
$hypericon-scommerce: "\e99e";
$hypericon-search-link: "\e969";
$hypericon-search-solid: "\e96d";
$hypericon-server-ico: "\e96e";
$hypericon-server-icon: "\e96f";
$hypericon-server-icon-inf: "\e970";
$hypericon-server-off: "\e971";
$hypericon-share: "\e972";
$hypericon-shield: "\e973";
$hypericon-shield-alt-solid: "\e974";
$hypericon-smartphone: "\e9a5";
$hypericon-solar-system: "\e975";
$hypericon-sort-down-solid: "\e976";
$hypericon-sort-solid: "\e977";
$hypericon-sort-up-solid: "\e978";
$hypericon-ssd: "\e979";
$hypericon-star-half: "\e97a";
$hypericon-star-regular: "\e97b";
$hypericon-star-solid: "\e97c";
$hypericon-support: "\e97d";
$hypericon-tags: "\e9ce";
$hypericon-target-lock: "\e9ca";
$hypericon-thematic-domains: "\e9b4";
$hypericon-time-icon: "\e97e";
$hypericon-times-solid: "\e980";
$hypericon-tm: "\e9ab";
$hypericon-translator: "\e981";
$hypericon-tux: "\e982";
$hypericon-twitter: "\e983";
$hypericon-twitter-square-brands: "\e98d";
$hypericon-typo3: "\e98e";
$hypericon-ua-domains: "\e98f";
$hypericon-ubuntu: "\e994";
$hypericon-uk-domain: "\e99f";
$hypericon-uk-emblem: "\e9a3";
$hypericon-umbraco: "\e9ac";
$hypericon-umi: "\e9ae";
$hypericon-uniE909: "\e9b8";
$hypericon-upload: "\e9b9";
$hypericon-upload-circle: "\e9ba";
$hypericon-usa-domain: "\e9bb";
$hypericon-usa-emblem: "\e9bc";
$hypericon-user-solid: "\e9bd";
$hypericon-vds: "\e9be";
$hypericon-visa: "\e9bf";
$hypericon-vps: "\e9c0";
$hypericon-vr-cube: "\e9c1";
$hypericon-vs-code: "\e9c2";
$hypericon-wildcard-ssl: "\e9c3";
$hypericon-windows: "\e9c4";
$hypericon-word-counting: "\e9c5";
$hypericon-wordpress: "\e9c6";
$hypericon-youtube: "\e9c7";
$hypericon-youtube-alt: "\e9c8";
$hypericon-youtube-square-brands: "\e9cb";
