.color-picker {
  position: relative;
  width: 169px;

  .custom-input {
    font-size: 0.889rem;
  }

  .input-color {
    position: absolute;
    width: 39px;
    right: 4px;
    background: black;
    border-radius: 50%;
    aspect-ratio: 1 / 1;
    border: 1px solid black;
  }
}
