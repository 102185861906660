@use '../custom-variables/colors' as *;
@use '../custom-variables/grid';

.compare-block {
  padding: 18px 33px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  background: white;

  .description {
    min-width: 47px;
  }

  &-text {
    width: 100%;
    text-align: center;
  }

  .hypericon-minus-solid {
    text-align: center;
  }

  .vr {
    align-self: center;
    width: 2px;
    height: 33%;
  }

  @media (max-width: grid.get-grid-bp('xl')) {
    padding: 1rem 14px;
  }

  @media (min-width: grid.get-grid-bp('lg')) and (max-width: grid.get-grid-bp('xl')) {
    &-text {
      font-size: 0.78rem;
    }

    .description {
      font-size: 1.11rem !important;
      min-width: 40px;
    }
  }

  @media (max-width: grid.get-grid-bp('lg')) {
    &-text {
      width: auto;
    }

    padding: 1rem;

    #first {
      margin-left: 1.5rem;
    }

    #second {
      margin-right: 1.5rem;
    }
  }

  @media (max-width: grid.get-grid-bp('sm')) {

    &-text {
      font-size: 0.89rem;
    }

    #first,
    #second {
      margin-left: unset;
      margin-right: unset;
    }
  }
}
