@use './icon-variables' as *;
@use '../custom-variables/colors' as *;

@font-face {
    font-family: '#{$icomoon-font-family}';
    src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?i5kq0n');
    src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?i5kq0n#iefix') format('embedded-opentype'),
      url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?i5kq0n') format('truetype'),
      url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?i5kq0n') format('woff'),
      url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?i5kq0n##{$icomoon-font-family}') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  .hypericons {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .hypericon-1c-bitrix {
    &:before {
      content: $hypericon-1c-bitrix;
    }
  }
  .hypericon-uniE9CF {
    &:before {
      content: $hypericon-uniE9CF;
    }
  }
  .hypericon-T {
    &:before {
      content: $hypericon-T;
    }
  }
  .hypericon-a-z-icon {
    &:before {
      content: $hypericon-a-z-icon;
    }
  }
  .hypericon-adv-cash {
    &:before {
      content: $hypericon-adv-cash;
    }
  }
  .hypericon-africa-icon {
    &:before {
      content: $hypericon-africa-icon;
    }
  }
  .hypericon-ai-brain {
    &:before {
      content: $hypericon-ai-brain;
    }
  }
  .hypericon-amiro {
    &:before {
      content: $hypericon-amiro;
    }
  }
  .hypericon-angle-down-solid {
    &:before {
      content: $hypericon-angle-down-solid;
    }
  }
  .hypericon-angle-li-right {
    &:before {
      content: $hypericon-angle-li-right;
    }
  }
  .hypericon-angle-right-solid {
    &:before {
      content: $hypericon-angle-right-solid;
    }
  }
  .hypericon-angle-up-solid {
    &:before {
      content: $hypericon-angle-up-solid;
    }
  }
  .hypericon-apple-pay-logo {
    &:before {
      content: $hypericon-apple-pay-logo;
    }
  }
  .hypericon-arrow {
    &:before {
      content: $hypericon-arrow;
    }
  }
  .hypericon-arrow-down {
    &:before {
      content: $hypericon-arrow-down;
    }
  }
  .hypericon-arrow-down-solid {
    &:before {
      content: $hypericon-arrow-down-solid;
    }
  }
  .hypericon-arrow-left {
    &:before {
      content: $hypericon-arrow-left;
    }
  }
  .hypericon-arrow-right {
    &:before {
      content: $hypericon-arrow-right;
    }
  }
  .hypericon-arrow-up {
    &:before {
      content: $hypericon-arrow-up;
    }
  }
  .hypericon-asia-icon {
    &:before {
      content: $hypericon-asia-icon;
    }
  }
  .hypericon-at-solid {
    &:before {
      content: $hypericon-at-solid;
    }
  }
  .hypericon-atlas-solid {
    &:before {
      content: $hypericon-atlas-solid;
    }
  }
  .hypericon-bitcoin-logo {
    &:before {
      content: $hypericon-bitcoin-logo;
    }
  }
  .hypericon-browser {
    &:before {
      content: $hypericon-browser;
    }
  }
  .hypericon-building-solid {
    &:before {
      content: $hypericon-building-solid;
    }
  }
  .hypericon-cake {
    &:before {
      content: $hypericon-cake;
    }
  }
  .hypericon-calendar {
    &:before {
      content: $hypericon-calendar;
    }
  }
  .hypericon-calendar-alt {
    &:before {
      content: $hypericon-calendar-alt;
    }
  }
  .hypericon-calendar-tiny {
    &:before {
      content: $hypericon-calendar-tiny;
    }
  }
  .hypericon-card {
    &:before {
      content: $hypericon-card;
    }
  }
  .hypericon-card-2 {
    &:before {
      content: $hypericon-card-2;
    }
  }
  .hypericon-card-icon-1 {
    &:before {
      content: $hypericon-card-icon-1;
    }
  }
  .hypericon-card-icon-infinity {
    &:before {
      content: $hypericon-card-icon-infinity;
    }
  }
  .hypericon-cart {
    &:before {
      content: $hypericon-cart;
    }
  }
  .hypericon-cart-alt {
    &:before {
      content: $hypericon-cart-alt;
    }
  }
  .hypericon-cdn {
    &:before {
      content: $hypericon-cdn;
    }
  }
  .hypericon-centos {
    &:before {
      content: $hypericon-centos;
    }
  }
  .hypericon-cert {
    &:before {
      content: $hypericon-cert;
    }
  }
  .hypericon-cert-alt {
    &:before {
      content: $hypericon-cert-alt;
    }
  }
  .hypericon-chart {
    &:before {
      content: $hypericon-chart;
    }
  }
  .hypericon-chat-gpt {
    &:before {
      content: $hypericon-chat-gpt;
    }
  }
  .hypericon-cheap-domains {
    &:before {
      content: $hypericon-cheap-domains;
    }
  }
  .hypericon-check-solid {
    &:before {
      content: $hypericon-check-solid;
    }
  }
  .hypericon-check-solid-alt {
    &:before {
      content: $hypericon-check-solid-alt;
    }
  }
  .hypericon-check-window {
    &:before {
      content: $hypericon-check-window;
    }
  }
  .hypericon-cloud-traffic {
    &:before {
      content: $hypericon-cloud-traffic;
    }
  }
  .hypericon-email {
    &:before {
      content: $hypericon-email;
    }
  }
  .hypericon-mobile {
    &:before {
      content: $hypericon-mobile;
    }
  }
  .hypericon-qr {
    &:before {
      content: $hypericon-qr;
    }
  }
  .hypericon-sms {
    &:before {
      content: $hypericon-sms;
    }
  }
  .hypericon-telegram {
    &:before {
      content: $hypericon-telegram;
    }
  }
  .hypericon-twitter-x {
    &:before {
      content: $hypericon-twitter-x;
    }
  }
  .hypericon-vCard {
    &:before {
      content: $hypericon-vCard;
    }
  }
  .hypericon-wifi {
    &:before {
      content: $hypericon-wifi;
    }
  }
  .hypericon-uniE9DA {
    &:before {
      content: $hypericon-uniE9DA;
    }
  }
  .hypericon-cloud-upload {
    &:before {
      content: $hypericon-cloud-upload;
    }
  }
  .hypericon-cms {
    &:before {
      content: $hypericon-cms;
    }
  }
  .hypericon-code {
    &:before {
      content: $hypericon-code;
    }
  }
  .hypericon-construct {
    &:before {
      content: $hypericon-construct;
    }
  }
  .hypericon-copy-btn {
    &:before {
      content: $hypericon-copy-btn;
      // this color is in the right place
      color: $gray-700;
    }
  }
  .hypericon-cpanel {
    &:before {
      content: $hypericon-cpanel;
    }
  }
  .hypericon-cs-cart {
    &:before {
      content: $hypericon-cs-cart;
    }
  }
  .hypericon-cyrcle-cake {
    &:before {
      content: $hypericon-cyrcle-cake;
    }
  }
  .hypericon-dashboard {
    &:before {
      content: $hypericon-dashboard;
    }
  }
  .hypericon-db {
    &:before {
      content: $hypericon-db;
    }
  }
  .hypericon-db-sync {
    &:before {
      content: $hypericon-db-sync;
    }
  }
  .hypericon-debian {
    &:before {
      content: $hypericon-debian;
    }
  }
  .hypericon-destination {
    &:before {
      content: $hypericon-destination;
    }
  }
  .hypericon-device-hdd {
    &:before {
      content: $hypericon-device-hdd;
    }
  }
  .hypericon-device-ssd {
    &:before {
      content: $hypericon-device-ssd;
    }
  }
  .hypericon-devices {
    &:before {
      content: $hypericon-devices;
    }
  }
  .hypericon-diafan {
    &:before {
      content: $hypericon-diafan;
    }
  }
  .hypericon-dice-solid {
    &:before {
      content: $hypericon-dice-solid;
    }
  }
  .hypericon-digital-security {
    &:before {
      content: $hypericon-digital-security;
    }
  }
  .hypericon-direction-field {
    &:before {
      content: $hypericon-direction-field;
    }
  }
  .hypericon-dle {
    &:before {
      content: $hypericon-dle;
    }
  }
  .hypericon-dns-circle {
    &:before {
      content: $hypericon-dns-circle;
    }
  }
  .hypericon-double-stack {
    &:before {
      content: $hypericon-double-stack;
    }
  }
  .hypericon-double-stack-tiny {
    &:before {
      content: $hypericon-double-stack-tiny;
    }
  }
  .hypericon-drupal {
    &:before {
      content: $hypericon-drupal;
    }
  }
  .hypericon-dv-ssl {
    &:before {
      content: $hypericon-dv-ssl;
    }
  }
  .hypericon-education-cap {
    &:before {
      content: $hypericon-education-cap;
    }
  }
  .hypericon-education-monitor {
    &:before {
      content: $hypericon-education-monitor;
    }
  }
  .hypericon-eu-domain {
    &:before {
      content: $hypericon-eu-domain;
    }
  }
  .hypericon-eu-domains {
    &:before {
      content: $hypericon-eu-domains;
    }
  }
  .hypericon-eu-emblem {
    &:before {
      content: $hypericon-eu-emblem;
    }
  }
  .hypericon-ev-ssl {
    &:before {
      content: $hypericon-ev-ssl;
    }
  }
  .hypericon-event-ring {
    &:before {
      content: $hypericon-event-ring;
    }
  }
  .hypericon-exclamation {
    &:before {
      content: $hypericon-exclamation;
    }
  }
  .hypericon-eye-slash-solid {
    &:before {
      content: $hypericon-eye-slash-solid;
    }
  }
  .hypericon-eye-solid {
    &:before {
      content: $hypericon-eye-solid;
    }
  }
  .hypericon-facebook {
    &:before {
      content: $hypericon-facebook;
    }
  }
  .hypericon-facebook-square-brands {
    &:before {
      content: $hypericon-facebook-square-brands;
    }
  }
  .hypericon-file-checker {
    &:before {
      content: $hypericon-file-checker;
    }
  }
  .hypericon-flag-alt {
    &:before {
      content: $hypericon-flag-alt;
    }
  }
  .hypericon-flag-solid {
    &:before {
      content: $hypericon-flag-solid;
    }
  }
  .hypericon-foreign-domains {
    &:before {
      content: $hypericon-foreign-domains;
    }
  }
  .hypericon-free {
    &:before {
      content: $hypericon-free;
    }
  }
  .hypericon-glasses {
    &:before {
      content: $hypericon-glasses;
    }
  }
  .hypericon-go-arrow {
    &:before {
      content: $hypericon-go-arrow;
    }
  }
  .hypericon-google {
    &:before {
      content: $hypericon-google;
    }
  }
  .hypericon-google-pay-logo {
    &:before {
      content: $hypericon-google-pay-logo;
    }
  }
  .hypericon-hand {
    &:before {
      content: $hypericon-hand;
    }
  }
  .hypericon-hard-drive {
    &:before {
      content: $hypericon-hard-drive;
    }
  }
  .hypericon-headphones {
    &:before {
      content: $hypericon-headphones;
    }
  }
  .hypericon-home-solid {
    &:before {
      content: $hypericon-home-solid;
    }
  }
  .hypericon-hostcms {
    &:before {
      content: $hypericon-hostcms;
    }
  }
  .hypericon-icon {
    &:before {
      content: $hypericon-icon;
    }
  }
  .hypericon-infinit-in-circle {
    &:before {
      content: $hypericon-infinit-in-circle;
    }
  }
  .hypericon-infinity {
    &:before {
      content: $hypericon-infinity;
    }
  }
  .hypericon-info-circle {
    &:before {
      content: $hypericon-info-circle;
    }
  }
  .hypericon-info-solid {
    &:before {
      content: $hypericon-info-solid;
    }
  }
  .hypericon-instagram {
    &:before {
      content: $hypericon-instagram;
    }
  }
  .hypericon-ip-address {
    &:before {
      content: $hypericon-ip-address;
    }
  }
  .hypericon-joomla {
    &:before {
      content: $hypericon-joomla;
    }
  }
  .hypericon-key {
    &:before {
      content: $hypericon-key;
    }
  }
  .hypericon-key-solid {
    &:before {
      content: $hypericon-key-solid;
    }
  }
  .hypericon-lego {
    &:before {
      content: $hypericon-lego;
    }
  }
  .hypericon-letter {
    &:before {
      content: $hypericon-letter;
    }
  }
  .hypericon-link {
    &:before {
      content: $hypericon-link;
    }
  }
  .hypericon-loader {
    &:before {
      content: $hypericon-loader;
    }
  }
  .hypericon-location {
    &:before {
      content: $hypericon-location;
    }
  }
  .hypericon-location-arrow-solid {
    &:before {
      content: $hypericon-location-arrow-solid;
    }
  }
  .hypericon-lock {
    &:before {
      content: $hypericon-lock;
    }
  }
  .hypericon-lock-open {
    &:before {
      content: $hypericon-lock-open;
    }
  }
  .hypericon-lock-rounded {
    &:before {
      content: $hypericon-lock-rounded;
    }
  }
  .hypericon-magento {
    &:before {
      content: $hypericon-magento;
    }
  }
  .hypericon-map-point {
    &:before {
      content: $hypericon-map-point;
    }
  }
  .hypericon-mastercard {
    &:before {
      content: $hypericon-mastercard;
    }
  }
  .hypericon-mc-exchange {
    &:before {
      content: $hypericon-mc-exchange;
    }
  }
  .hypericon-minus-solid {
    &:before {
      content: $hypericon-minus-solid;
    }
  }
  .hypericon-modx {
    &:before {
      content: $hypericon-modx;
    }
  }
  .hypericon-monitor {
    &:before {
      content: $hypericon-monitor;
    }
  }
  .hypericon-mono {
    &:before {
      content: $hypericon-mono;
    }
  }
  .hypericon-moodle {
    &:before {
      content: $hypericon-moodle;
    }
  }
  .hypericon-motocms {
    &:before {
      content: $hypericon-motocms;
    }
  }
  .hypericon-netcat {
    &:before {
      content: $hypericon-netcat;
    }
  }
  .hypericon-nvme {
    &:before {
      content: $hypericon-nvme;
    }
  }
  .hypericon-office {
    &:before {
      content: $hypericon-office;
    }
  }
  .hypericon-okaycms {
    &:before {
      content: $hypericon-okaycms;
    }
  }
  .hypericon-opencart {
    &:before {
      content: $hypericon-opencart;
    }
  }
  .hypericon-optical-disk {
    &:before {
      content: $hypericon-optical-disk;
    }
  }
  .hypericon-ov-ssl {
    &:before {
      content: $hypericon-ov-ssl;
    }
  }
  .hypericon-p24-logo {
    &:before {
      content: $hypericon-p24-logo;
    }
  }
  .hypericon-passport {
    &:before {
      content: $hypericon-passport;
    }
  }
  .hypericon-paypal-logo {
    &:before {
      content: $hypericon-paypal-logo;
    }
  }
  .hypericon-pen {
    &:before {
      content: $hypericon-pen;
    }
  }
  .hypericon-percent {
    &:before {
      content: $hypericon-percent;
    }
  }
  .hypericon-perfecto-cms {
    &:before {
      content: $hypericon-perfecto-cms;
    }
  }
  .hypericon-phone-solid {
    &:before {
      content: $hypericon-phone-solid;
    }
  }
  .hypericon-php {
    &:before {
      content: $hypericon-php;
    }
  }
  .hypericon-phpshop {
    &:before {
      content: $hypericon-phpshop;
    }
  }
  .hypericon-plus-solid {
    &:before {
      content: $hypericon-plus-solid;
    }
  }
  .hypericon-popular-domain {
    &:before {
      content: $hypericon-popular-domain;
    }
  }
  .hypericon-prestashop {
    &:before {
      content: $hypericon-prestashop;
    }
  }
  .hypericon-quotes {
    &:before {
      content: $hypericon-quotes;
    }
  }
  .hypericon-redirect {
    &:before {
      content: $hypericon-redirect;
    }
  }
  .hypericon-rubber {
    &:before {
      content: $hypericon-rubber;
    }
  }
  .hypericon-sale-window {
    &:before {
      content: $hypericon-sale-window;
    }
  }
  .hypericon-santafox {
    &:before {
      content: $hypericon-santafox;
    }
  }
  .hypericon-save {
    &:before {
      content: $hypericon-save;
    }
  }
  .hypericon-scales {
    &:before {
      content: $hypericon-scales;
    }
  }
  .hypericon-scheme-distributed {
    &:before {
      content: $hypericon-scheme-distributed;
    }
  }
  .hypericon-scheme-key {
    &:before {
      content: $hypericon-scheme-key;
    }
  }
  .hypericon-scommerce {
    &:before {
      content: $hypericon-scommerce;
    }
  }
  .hypericon-search-link {
    &:before {
      content: $hypericon-search-link;
    }
  }
  .hypericon-search-solid {
    &:before {
      content: $hypericon-search-solid;
    }
  }
  .hypericon-server-ico {
    &:before {
      content: $hypericon-server-ico;
    }
  }
  .hypericon-server-icon {
    &:before {
      content: $hypericon-server-icon;
    }
  }
  .hypericon-server-icon-inf {
    &:before {
      content: $hypericon-server-icon-inf;
    }
  }
  .hypericon-server-off {
    &:before {
      content: $hypericon-server-off;
    }
  }
  .hypericon-share {
    &:before {
      content: $hypericon-share;
    }
  }
  .hypericon-shield {
    &:before {
      content: $hypericon-shield;
    }
  }
  .hypericon-shield-alt-solid {
    &:before {
      content: $hypericon-shield-alt-solid;
    }
  }
  .hypericon-smartphone {
    &:before {
      content: $hypericon-smartphone;
    }
  }
  .hypericon-solar-system {
    &:before {
      content: $hypericon-solar-system;
    }
  }
  .hypericon-sort-down-solid {
    &:before {
      content: $hypericon-sort-down-solid;
    }
  }
  .hypericon-sort-solid {
    &:before {
      content: $hypericon-sort-solid;
    }
  }
  .hypericon-sort-up-solid {
    &:before {
      content: $hypericon-sort-up-solid;
    }
  }
  .hypericon-ssd {
    &:before {
      content: $hypericon-ssd;
    }
  }
  .hypericon-star-half {
    &:before {
      content: $hypericon-star-half;
    }
  }
  .hypericon-star-regular {
    &:before {
      content: $hypericon-star-regular;
    }
  }
  .hypericon-star-solid {
    &:before {
      content: $hypericon-star-solid;
    }
  }
  .hypericon-support {
    &:before {
      content: $hypericon-support;
    }
  }
  .hypericon-tags {
    &:before {
      content: $hypericon-tags;
    }
  }
  .hypericon-target-lock {
    &:before {
      content: $hypericon-target-lock;
    }
  }
  .hypericon-thematic-domains {
    &:before {
      content: $hypericon-thematic-domains;
    }
  }
  .hypericon-time-icon {
    &:before {
      content: $hypericon-time-icon;
    }
  }
  .hypericon-times-solid {
    &:before {
      content: $hypericon-times-solid;
    }
  }
  .hypericon-tm {
    &:before {
      content: $hypericon-tm;
    }
  }
  .hypericon-translator {
    &:before {
      content: $hypericon-translator;
    }
  }
  .hypericon-tux {
    &:before {
      content: $hypericon-tux;
    }
  }
  .hypericon-twitter {
    &:before {
      content: $hypericon-twitter;
    }
  }
  .hypericon-twitter-square-brands {
    &:before {
      content: $hypericon-twitter-square-brands;
    }
  }
  .hypericon-typo3 {
    &:before {
      content: $hypericon-typo3;
    }
  }
  .hypericon-ua-domains {
    &:before {
      content: $hypericon-ua-domains;
    }
  }
  .hypericon-ubuntu {
    &:before {
      content: $hypericon-ubuntu;
    }
  }
  .hypericon-uk-domain {
    &:before {
      content: $hypericon-uk-domain;
    }
  }
  .hypericon-uk-emblem {
    &:before {
      content: $hypericon-uk-emblem;
    }
  }
  .hypericon-umbraco {
    &:before {
      content: $hypericon-umbraco;
    }
  }
  .hypericon-umi {
    &:before {
      content: $hypericon-umi;
    }
  }
  .hypericon-uniE909 {
    &:before {
      content: $hypericon-uniE909;
    }
  }
  .hypericon-upload {
    &:before {
      content: $hypericon-upload;
    }
  }
  .hypericon-upload-circle {
    &:before {
      content: $hypericon-upload-circle;
    }
  }
  .hypericon-usa-domain {
    &:before {
      content: $hypericon-usa-domain;
    }
  }
  .hypericon-usa-emblem {
    &:before {
      content: $hypericon-usa-emblem;
    }
  }
  .hypericon-user-solid {
    &:before {
      content: $hypericon-user-solid;
    }
  }
  .hypericon-vds {
    &:before {
      content: $hypericon-vds;
    }
  }
  .hypericon-visa {
    &:before {
      content: $hypericon-visa;
    }
  }
  .hypericon-vps {
    &:before {
      content: $hypericon-vps;
    }
  }
  .hypericon-vr-cube {
    &:before {
      content: $hypericon-vr-cube;
    }
  }
  .hypericon-vs-code {
    &:before {
      content: $hypericon-vs-code;
    }
  }
  .hypericon-wildcard-ssl {
    &:before {
      content: $hypericon-wildcard-ssl;
    }
  }
  .hypericon-windows {
    &:before {
      content: $hypericon-windows;
    }
  }
  .hypericon-word-counting {
    &:before {
      content: $hypericon-word-counting;
    }
  }
  .hypericon-wordpress {
    &:before {
      content: $hypericon-wordpress;
    }
  }
  .hypericon-youtube {
    &:before {
      content: $hypericon-youtube;
    }
  }
  .hypericon-youtube-alt {
    &:before {
      content: $hypericon-youtube-alt;
    }
  }
  .hypericon-youtube-square-brands {
    &:before {
      content: $hypericon-youtube-square-brands;
    }
  }

