@use 'colors' as *;
@use 'mixins' as mix;
@use 'flags' as f;

.custom-input-group .custom-select {
  cursor: pointer;
}

select.custom-select {
  display: none;
}

div.custom-select {
  background: $white;

  border: 1px solid $black;
  border-radius: 30px;

  height: 47px;
  max-width: 326px;
  width: 100%;
  padding: 7.5px 20px;

  @media (max-width:500px) {
    padding: 1.5px 20px;
    height: 35px;
  }

  &~.custom-input-placeholder.after {
    padding-block: 17px;

    &:before {
      --arrow-size: 12px;
      content: "";

      display: block;

      width: var(--arrow-size);
      height: var(--arrow-size);

      //   margin-left: -10px;

      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23090909'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: center;
      background-size: var(--arrow-size);
    }
  }

  .title {
    font-weight: 400;
    font-size: 20px;
    line-height: 22px;

    display: block;
    height: 100%;
    padding-block: 1%;

    color: $custom-select-title-text;
  }

  .option-list-wrapper {
    display: none;

    &.show {
      display: block;
    }

    position: absolute;

    margin-left: -40px;
    margin-top: 25px;

    @media (max-width:500px) {
      margin-top: 10px;
    }

    max-width: 340px;
    width: 100%;

    background: $options-list-bg;
    border: $options-list-border;
    border-radius: 25px;

    padding-left: 38px;
    padding-right: 26px;
    padding-block: 19px;

    .option-list {
      position: relative;

      display: flex;
      flex-direction: column;

      margin: 0;
      padding: 0;

      --max-rows: 8;

      /* adjust height based on font size */
      max-height: calc(var(--max-rows) * 1.3em);
      overflow-y: scroll;

      @include mix.custom-scrollbar;

      .option {
        display: flex;
        align-items: center;
        justify-content: start;


        &:hover {
          background: $gray-100;
        }

        padding-left: 8px;

        color: $option-unselected-text;

        list-style: none;

        white-space: nowrap;

        height: 28px;

        font-weight: 400;
        font-size: 16px;
        line-height: 22px;

        cursor: pointer;

        &[data-option-is-selected=true] {
          color: $option-selected-text;
          background: unset;
        }

        &[data-option-hr=true] {
          border-bottom: 1px solid #ccc;
          margin-bottom: 5px;
          padding-bottom: 5px;
          height: auto;

          cursor: auto;

          &:hover {
            background: unset;
          }
        }
      }
    }
  }
}

.custom-input-group.tel-input-group {
  div.tel-input-select {
    text-align: center;


    width: 12rem;

    border-right: unset !important;

    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    padding-right: 5px;

    .title {
      display: block;

      height: 100%;

      padding-block: 3%;
      font-size: 1rem;

      --arrow-size: 10px;

      &::after:not(.without-arrow) {
        @include mix.card-more-btn;
        height: 100%;
      }
    }

    .option-list {
      .option[data-country-flag] {
        &::before {
          content: "";
          background-image: url('/img/flags.webp');
          width: map-get(f.$item-width-maps, f.$standard-country);
          margin-right: 10px;
        }
      }

      @each $iso, $width in f.$item-width-maps {
        .option[data-country-flag="#{$iso}"] {
          &::before {
            @if $width !=map-get(f.$item-width-maps, f.$standard-country) {
              width: $width;
            }

            height: map-get(f.$item-height-maps, $iso);
            background-position: map-get(f.$item-bg-position-maps, $iso);
          }
        }
      }
    }
  }
}

.custom-select-arrow {
  select {
    appearance: none;
  }

  &::after {
    content: "";
    --arrow-height: 4px;
    --arrow-size: 5px;
    --arrow-color: black;
    position: absolute;
    filter: drop-shadow(-2px 8.5px 5px rgba(9, 9, 9, 0.06));
    border-top: var(--arrow-height) solid var(--arrow-color);
    border-right: var(--arrow-size) solid transparent;
    border-left: var(--arrow-size) solid transparent;
    border-bottom: 0;
    margin-top: 1.2rem;
    margin-left: -20px;
  }
}
