@use '../custom-variables/colors' as *;
@use '../custom-variables/grid';

.nav-bar-container {
  display: flex;
  flex-direction: column;
}

.link-bar-container {
  background: $link-bar-bg;

  .list-dropdown {
    .dropdown-button {
      display: flex;
      align-items: center;

      color: $link-bar-list-btn-text-color;

      cursor: pointer;

      i {
        margin-right: 4px;
      }

      .selected {}
    }

    .list {
      padding-block: 16px;
      padding-inline: 22px;

      border-radius: 25px;
      border: $link-bar-list-border-color;

      left: -20px;

      .item {
        font-size: 16px;
        font-weight: 400;

        cursor: pointer;

        padding: 3px 5px;

        color: $link-bar-list-item-text-color;

        &:hover {
          background: $link-bar-list-item-border-color-hover;
          color: $link-bar-list-item-text-color-hover;
        }

        &.active {
          color: $link-bar-list-item-text-color-active;
        }

        a {
          color: inherit;
          text-decoration: none;
        }
      }
    }
  }

  .link-bar {
    padding-block: 0;

    @media (max-width: grid.get-grid-bp('lg')) {
      padding-bottom: 0.3rem;
    }

    @media (max-width: grid.get-grid-bp('md')) {
      justify-content: center;
      padding-bottom: 0;
    }

    .phone-block {
      display: flex;
      flex-direction: column;

      .text {
        color: $link-bar-nav-bar-text-color;

        font-size: 12px;
        font-weight: 300;
        line-height: 1.3;
      }

      .phone {
        .number {
          color: $link-bar-nav-bar-text-color;
          text-decoration: none;

          font-size: 24px;
          font-weight: 600;
          line-height: 1.3;

        }
      }

      @media (max-width: grid.get-grid-bp('md')) {
        align-items: center;
      }
    }

    .links-block {
      .links-list {
        display: flex;
        justify-content: space-between;

        min-width: 568px;

        @media (max-width: grid.get-grid-bp('xl')) {
          min-width: 440px;
        }

        @media (max-width: grid.get-grid-bp('lg')) {
          min-width: 360px;
        }

        @media (max-width: grid.get-grid-bp('md')) {
          display: none;
        }

        .nav-item {

          .nav-link {
            color: $link-bar-nav-bar-text-color;

            padding: 0;
            padding-block: 0.5em;

            font-size: 18px;

            @media (max-width: grid.get-grid-bp('lg')) {
              font-size: 16px;
            }

            font-weight: 400;
            line-height: 1.3;


            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .btns-block {
      display: flex;
      align-items: center;
      justify-content: start;

      >* {
        &:not(:first-child) {
          margin-left: 30px;

          @media (max-width: grid.get-grid-bp('lg')) {
            margin-left: 10px;
          }
        }
      }

      @media (max-width: grid.get-grid-bp('lg')) {
        margin-left: auto;
      }

      @media (max-width: grid.get-grid-bp('md')) {
        display: none;
      }
    }

    .actions-block {}

    .currency-block {
      .list {
        max-width: 100px;

        @media (max-width: 1500px) {
          left: -250%;
        }
      }
    }
  }
}

.pages-bar-container {
  .pages-bar {
    display: flex;
    align-items: center;

    @media (max-width: grid.get-grid-bp('md')) {
      justify-content: space-between;
      flex-wrap: wrap;
    }

    padding-block: 10px;

    .logo-block {
      display: flex;
      justify-content: center;
    }

    .nav-services {
      display: flex;
      align-items: center;

      margin-left: 210px;

      @media (max-width: grid.get-grid-bp('lg')) {
        justify-content: space-between;
        margin-left: auto;
      }

      @media (max-width: grid.get-grid-bp('md')) {
        display: none;
      }

      .nav-service {
        &:not(:last-child) {
          margin-right: 5rem;

          @media (max-width: grid.get-grid-bp('lg')) {
            margin-right: 3rem;
          }

          @media (max-width: grid.get-grid-bp('md')) {
            margin-right: 0;
          }
        }

        .dropdown-button,
        a {
          display: flex;
          align-items: center;
          text-decoration: none;

          font-size: 20px;
          font-weight: 400;
          line-height: 1.3;

          &:hover,
          &:active {
            text-underline-offset: 5px;
            text-decoration: underline;
          }
        }
      }

      &-mobile {
        display: none;

        @media (max-width: grid.get-grid-bp('md')) {
          display: flex;
          align-content: center;
          justify-content: end;

          margin-left: auto;
        }

        .navbar-toggle {
          .icon-bar {
            display: block;
            height: 3px;
            width: 30px;
            border-radius: 10px;
            background-color: $link-bar-nav-bar-mobile-bars-color;

            &+.icon-bar {
              margin-top: 6px;
            }
          }

          &:not(.collapsed) {
            background-color: $link-bar-nav-bar-mobile-bars-not-collapsed-bg;
          }
        }

        &-collapse {
          @media (min-width: grid.get-grid-bp('md')) {
            display: none;
          }
        }
      }
    }
  }

  // Style for active currency selected on mobile menu 😐
  .active {
    color: $link-bar-active-currency-text-color !important;
  }

  // Change Size for icon on currency block on mobile menu
  .font-size-12 {
    font-size: 12px;
  }
}

.mobile-nav-hr {
  margin: 0rem 0;
  color: #626262;
  border-top: 1px solid;
  opacity: 0.25;
}

.link-nav-menu {
  margin-left: 2.5em;
}

//Mobile menu have specific margin from icons to text
.icon-marin-right {
  margin-right: 30px;
}

//Offcanvas must bee under main blue header with phone 😐
.offcanvas-end {
  top: 50px !important;
}
