@use '../custom-variables/colors' as *;

.domain-block {
  display: none;
  flex-wrap: wrap;
  padding: 19px;
  background-color: white;
  border: 1px solid $gray-500;
  border-radius: 10px;

  .domain-block-image {

    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    aspect-ratio: 1 / 1;
    margin-right: 23px;
    border-radius: 50%;
    background: $success-lite;

    i {
      font-size: 60px;
    }

    @media (max-width: 876px) {
      display: none;
    }
  }

  &.registered {
    .domain-block-image {
      background: $sasha-gray;
    }
  }
}
