@use '../custom-variables/colors' as *;

.bar {
  margin-top: 10px;

  .bar-name,
  .bar-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;

    color: $progress-bar-text-color;
  }
}

.progress {
  margin-top: 8px;

  background: $progress-bar-bg;

  box-shadow: inset 0px 4px 4px #00000040;

  border-radius: 10px;

  height: 5px;

  .progress-bar {
    background: $progress-bar-color;

    box-shadow: inset 0px 4px 4px #00000040;
    border-radius: 10px;
  }
}

.tools-bar {
  @extend .bar;
  margin-top: 0;
  margin-bottom: 10px;
  margin-inline: auto;
  width: 93%;

  .progress {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  .bar-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #B1B1B1;
  }
}
