@use 'colors';
@use 'grid';

.modal {
  .modal-dialog {
    @media (min-width: calc(grid.get-grid-bp('lg') + 1px)) {
      min-width: 830px;
    }

    .modal-content {
      padding: 40px 35px 50px 43px;

      @media (max-width: grid.get-grid-bp('md')) {
        padding-inline: 20px;
      }

      >* {
        border: unset;
        padding: 0;
      }

      .modal {
        &-header {
          display: flex;
          flex-direction: column;
          align-items: start;

          position: relative;

          .title-block {
            .title {
              display: flex;
              align-items: baseline;

              >* {
                margin: 0;
              }

              i {
                font-size: 38px;

                @media (max-width: grid.get-grid-bp('lg')) {
                  display: none;
                }
              }
            }

            .sub-title {
              margin: 0;

              >*,
              & {
                font-weight: 300;
                font-size: 20px;
                line-height: 30px;
              }
            }

            .log-modal-btn,
            .reg-modal-btn {
              @media (max-width: grid.get-grid-bp('sm')) {
                padding-left: 0;
              }
            }
          }

          .btn-close {
            position: absolute;
            top: 0;
            right: 0;
          }

          hr {
            margin: 0;
            margin-top: 11px;

            opacity: unset;

            width: 100%;

            border: 0;
            color: colors.$black;
            border-top: 1px solid;
            border-radius: 10px;
          }
        }

        &-body {
          .custom-input-group {
            width: 100%;
          }
        }

        &-footer {
          >* {
            margin: unset;
          }

          button[type="submit"] {
            @media (max-width: grid.get-grid-bp('lg')) {
              width: 100%;
            }
          }
        }
      }
    }
  }

  .input-label {
    font-weight: 300;
    font-size: 20px;
    line-height: 26px;
  }

  .form-check {
    padding-left: 1.6em;
  }

  .explanation {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.3;

    margin: 0;
  }

  .form-check-label {
    color: colors.$black;
    font-size: 20px;
    line-height: 30px;
    font-weight: 300;

  }
}

// Modals style
.modal {

  &.login-modal {

    .custom-input-group,
    .button {
      max-width: 343px;
      width: 100%;
    }
  }

  &.registration-modal,
  #Original-Modal {
    .type-of-reg {
      margin-top: 2.1rem;
    }

    .custom-input-group,
    .button {
      max-width: 340px;
      width: 100%;
    }

    .bar {
      max-width: 305px;
      width: 100%;
    }

    .password-block {
      .bar {
        margin-left: 5%;

        @media (max-width: grid.get-grid-bp('lg')) {
          margin-left: unset;
        }
      }
    }

    .organization-block {
      &:not(.active) {
        display: none;
      }
    }
  }

  &.gpt-modal {

    .custom-input-group,
    .button {
      max-width: 370px;
      width: 100%;
    }

    .gpt-answer-block {
      &:not(.active) {
        display: none;
      }

      .title {
        font-size: 24px;
        font-weight: 600;
        line-height: 1.3;
      }

      .output-block {
        .domains-output {
          .domain-to-check {
            display: flex;
            align-items: baseline;

            .domain-name {
              color: colors.$satan-black;
              text-decoration: underline;

              font-size: 20px;
              font-weight: 400;
              line-height: 1.3;

              margin: 0;
            }

            .domain-zone {
              color: colors.$gray-700;
              text-decoration: none;

              font-size: 20px;
              font-weight: 400;
              line-height: 1.3;
            }
          }
        }
      }
    }
  }

  &.make-request-dedicated-server {

    .custom-input-group,
    .button {
      max-width: 370px;
      width: 100%;
    }
  }

  &.site-transfer-modal {
    .comments-for-trans-row {
      textarea {
        min-height: 144px;
      }
    }

    .input-block,
    .custom-input-group,
    .button {
      max-width: 370px;
      width: 100%;
    }

    .access-data {
      &:not(.active) {
        display: none !important;
      }
    }
  }

  &#Original-Modal {

    .custom-input-group,
    .button {
      max-width: 340px;
      width: 100%;
    }

    .modal-dialog {
      width: 800px;
    }

    .bar {
      max-width: 305px;
      width: 100%;
      margin-left: 5%;
    }

    @media (max-width: grid.get-grid-bp('lg')) {
      ul {
        width: 100% !important;
        margin-bottom: 2rem !important;
        text-align: center;

        li {
          &:not(.active) {
            display: flex;
            align-items: center;
            flex-direction: column;
          }
        }
      }

      .bar {
        margin-left: 0;
        margin-bottom: 2rem;
      }

      .tab-content {
        .modal-title {
          top: -190px !important;
        }

        .object-type {
          text-align: center;
        }
      }

      .modal-footer {
        justify-content: center;

        .button {
          max-width: unset;
        }
      }
    }

    @media (max-width: grid.get-grid-bp('md')) {
      .modal-header {

        .user,
        .auth-question {
          width: 100% !important;
        }

        .auth-question {
          float: unset;
        }
      }

      .modal-dialog {
        width: auto;
      }
    }
  }
}
