@use './custom-variables/colors' as *;

.main-container {
  background: $main-bg-gray;

  .custom-input-group {
    .custom-input-placeholder {

      &.before,
      &.after {
        background: white;

        @media (max-width:500px) {
          max-height: 47px;
          padding: 9px 0;
        }
      }
    }

    .custom-input {
      @media (max-width:500px) {
        max-height: 47px;
      }
    }

    .custom-input {
      max-width: none;
    }

    .custom-from-control {
      &:is(textarea) {
        padding-inline: 21px;
        padding-right: 2rem;
        padding-block: 11px;
      }
    }
  }

  .custom-input-group {

    div.custom-select {
      white-space: nowrap !important;
      padding: 11.5px 20px !important;
      height: 47px !important;

      .title {
        padding-block: 0;
      }

      .option-list-wrapper {
        max-width: 232px;

        .option-list {
          overflow-y: auto;
        }
      }
    }

    div.custom-input-placeholder.after {
      padding-block: 17px !important;
      padding-right: 1rem !important;
      height: 47px !important;
    }
  }
}

.tools-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 3rem 5%;
}

.with-scroll {
  position: relative;
  overflow: hidden;

  .before-scroll, .after-scroll {
    position: absolute;
    top: 16px;
    right: 12px;
    width: 5px;
    height: 0;
    border-radius: 10px;
    z-index: 3;
  }

  .before-scroll {
    background-color: $sasha-gray;
  }

  .after-scroll {
    background-color: $satan-black;
  }

  textarea {
    overflow: hidden;
  }
}

.output-block {
  border: 1px solid $gray-500;
  border-radius: 10px;
}

.grecaptcha-badge {
  display: none !important;
}

.red-alert-left {
  background: url('/tools/img/bg/pages-promo-section-bg.svg') no-repeat;
  background-size: cover;
}

.red-alert-right {
  background: url('/tools/img/bg/product-promo-section-bg.svg') no-repeat;
  background-size: cover;
}
