@use '../custom-variables/grid';
@use '../custom-variables/colors' as *;
@use '../hyper-icons/icon-variables' as icons;
@use '../custom-variables/mixins' as timer;

.event-promo {
  display: flex;
  align-items: center;

  padding: 27px 0;

  border-radius: 15px;

  .emoji-block {

    &,
    &>* {
      font-size: 30px;
    }
  }

  .text-block {

    &,
    &>* {
      font-size: 18px;
      font-weight: 400;
      line-height: 1.3;
      letter-spacing: -0.48px;
    }

    &>* {
      margin: 0;
    }

    a {
      color: inherit;

      text-decoration: underline;
    }

    margin-left: 108px;
  }

  .event-timer-block {
    @include timer.timer-block;

    .event-timer {
      @extend .timer;
    }
  }

  .event-action-block {
    .button-block {

      // Sorry but i cant use or extend regular bootstrap btn (or button in hyperhost variant) class
      .button {
        --bs-btn-padding-x: 27px;
        --bs-btn-padding-y: 2px;
        --bs-btn-font-family: inherit;
        --bs-btn-font-size: 18px;
        --bs-btn-font-weight: 600;
        --bs-btn-line-height: 36px;
        --bs-btn-line-height-sm: 26px;
        --bs-btn-line-height-lg: 40px;
        --bs-btn-color: #090909;
        --bs-btn-bg: transparent;
        --bs-btn-border-width: 2px;
        --bs-btn-border-color: transparent;
        --bs-btn-border-radius: 30px;
        --bs-btn-hover-border-color: transparent;
        --bs-btn-box-shadow: unset;
        --bs-btn-disabled-opacity: 0.65;
        --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), .5);
        display: inline-block;
        padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
        font-family: var(--bs-btn-font-family);
        font-weight: var(--bs-btn-font-weight);
        line-height: var(--bs-btn-line-height);
        color: var(--bs-btn-color);
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
        background-color: var(--bs-btn-bg);
        box-shadow: var(--bs-btn-box-shadow);
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        border-radius: var(--bs-btn-border-radius);
        outline: unset;
        font-size: var(--bs-btn-font-size);
        min-width: 160px;

        &:active {
          color: var(--bs-btn-active-color);
          background-color: var(--bs-btn-active-bg);
          box-shadow: var(--bs-btn-active-shadow);

          border: unset;
          border-color: unset;
          padding: calc(var(--bs-btn-border-width) + var(--bs-btn-padding-y)) calc(var(--bs-btn-border-width) + var(--bs-btn-padding-x));
        }

        &:hover {
          color: var(--bs-btn-hover-color);
          background-color: var(--bs-btn-hover-bg);
          box-shadow: var(--bs-btn-hover-shadow);

          border: unset;
          border-color: unset;
          padding: calc(var(--bs-btn-border-width) + var(--bs-btn-padding-y)) calc(var(--bs-btn-border-width) + var(--bs-btn-padding-x));
        }

        &:focus-visible {
          color: var(--bs-btn-hover-color);
          background-color: var(--bs-btn-hover-bg);
          border-color: var(--bs-btn-hover-border-color);
          outline: unset;
          box-shadow: var(--bs-btn-box-shadow), var(--bs-btn-focus-box-shadow);
        }

        &.button-event {
          --bs-btn-color: #090909;
          --bs-btn-border-color: #090909;
          --bs-btn-hover-color: #fff;
          --bs-btn-hover-bg: #090909;
          --bs-btn-hover-border-color: #090909;
          --bs-btn-hover-shadow: unset;
          --bs-btn-active-color: #fff;
          --bs-btn-active-bg: #090909;
          --bs-btn-active-border-color: unset;
          --bs-btn-active-shadow: unset;
        }
      }
    }

  }

  &.with {
    &-emoji {
      .emoji-block {
        margin-left: 60px;
      }

      .text-block {
        margin-left: 8px;
      }
    }

    &-timer {
      .event-timer-block {
        margin-left: auto;
        padding-bottom: 1em;
      }

      .event-action-block {
        margin-left: 30px !important;
      }
    }

    &-button {
      .event-action-block {
        margin-left: 67px;
        margin-right: 60px;
      }
    }

    &-promocode {
      .event-action-block {
        margin-left: 65px;
        margin-right: 67px;
      }
    }

    &-promocode:not(.with-timer),
    &-button:not(.with-timer) {
      .event-action-block {
        margin-left: auto;
      }
    }
  }

  .hidden {
    display: none;
  }

  @media (max-width: grid.get-grid-bp('lg')) {
    flex-direction: column;
    padding-inline: 2rem;

    .emoji-block {
      display: none;
    }

    .text-block {
      margin-left: 0 !important;

      margin-bottom: 0.8rem;

      text-align: center;
    }

    &.with-timer {
      .event-timer-block {
        margin-left: 0 !important;
        margin-bottom: 0.7rem;

        .event-timer {
          justify-content: center;
        }
      }

      .event-action-block {
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }

    .event-action-block {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  // Style types (presets)
  &.gradient-purple {
    background: $gradient-purple;
    color: $primary-black;

    @media (max-width: grid.get-grid-bp('lg')) {
      background: $gradient-purple-lg;
    }
  }

  &.dark {
    background: $primary-black;
    color: $gray-100;

    .event-action-block {
      .button.button-event {
        --bs-btn-color: #fff;
        --bs-btn-border-color: #fff;
        --bs-btn-hover-color: #fff;
        --bs-btn-hover-bg: rgba(255, 255, 255, 0.15);
        --bs-btn-hover-border-color: #fff;
        --bs-btn-hover-shadow: unset;
        --bs-btn-active-color: #fff;
        --bs-btn-active-bg: rgba(255, 255, 255, 0.15);
        --bs-btn-active-border-color: #fff;
        --bs-btn-active-shadow: unset;
      }

      .promo-code-block {
        border-color: $gray-100;
      }
    }
  }

  &.pumpkin {
    background: #FC7C1E;
    color: $primary-black;

    .event-action-block {
      .promo-code-block {
        border-color: #00000033;
      }
    }
  }

  &.gradient-blue {
    background: $gradient-blue;
    color: $primary-black;

    .event-action-block {
      .promo-code-block {
        border-color: #00000033;
      }
    }
  }

  &.red {
    background: #C33A41;
    color: $gray-100;

    .event-action-block {
      .button.button-event {
        --bs-btn-color: #fff;
        --bs-btn-border-color: #fff;
        --bs-btn-hover-color: #fff;
        --bs-btn-hover-bg: rgba(255, 255, 255, 0.15);
        --bs-btn-hover-border-color: #fff;
        --bs-btn-hover-shadow: unset;
        --bs-btn-active-color: #fff;
        --bs-btn-active-bg: rgba(255, 255, 255, 0.15);
        --bs-btn-active-border-color: #fff;
        --bs-btn-active-shadow: unset;
      }

      .promo-code-block {
        border-color: $gray-100;
      }
    }
  }

  &.gradient-aqua {
    background: $gradient-aqua;
    color: $primary-black;

    .event-action-block {
      .promo-code-block {
        border-color: #00000033;
      }
    }
  }

  &.gradient-purple-animated {
    background: $gradient-purple-animated;
    background-size: 400% 400%;
    animation: gradient 25s ease-in infinite;

    @keyframes gradient {
      0% {
        background-position: 0% 0%;
      }

      50% {
        background-position: 100% 50%;
      }

      100% {
        background-position: 0% 0%;
      }
    }
  }
}

.event-promo-section {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1019;

  .event-promo {
    position: relative;

    &.minimal {
      display: none;
    }

    .minimal-icon {
      display: flex;
      align-items: center;
      justify-content: center;

      cursor: pointer;

      position: absolute;

      width: 20px;
      height: 20px;

      right: 1rem;
      top: 0.5rem;

      opacity: 0.3;

      &:hover {
        opacity: 1;
      }

      &::before {
        content: icons.$hypericon-times-solid;
        position: absolute;

        font-family: icons.$icomoon-font-family;
        font-size: 20px;

        color: inherit;
      }
    }
  }

  &:not(.minimal) {
    .event-promo-minimal-toggle {
      display: none;
    }
  }

  .event-promo-minimal-toggle {
    --window-left-padding: 7rem;
    --window-bottom-padding: 1.45rem;

    display: flex;

    align-items: center;
    justify-content: center;


    width: 50px;
    height: 50px;

    position: absolute;

    border-radius: 50%;

    bottom: var(--window-bottom-padding);
    left: var(--window-left-padding);

    cursor: pointer;

    .minimal-icon {
      &::before {
        display: block;

        content: icons.$hypericon-event-ring;
        position: static;

        font-family: icons.$icomoon-font-family;
        font-size: 25px;

        color: inherit;

        margin-inline: auto;
      }
    }

    &.gradient-purple {
      background: $gradient-purple;

      @media (max-width: grid.get-grid-bp('lg')) {
        background: $gradient-purple-lg;
      }
    }

    &.dark {
      background: $primary-black;
      color: white;
    }

    &.pumpkin {
      background: #FC7C1E;
      color: white;
    }

    &.gradient-blue {
      background: $gradient-blue;
    }

    &.red {
      background: #C33A41;
    }

    &.gradient-aqua {
      background: $gradient-aqua;
    }

    &.gradient-purple-animated {
      background: $gradient-purple-animated;
      background-size: 400% 400%;
      color: $primary-black;
      animation: gradient 25s ease-in infinite;

      @keyframes gradient {
        0% {
          background-position: 0% 0%;
        }

        50% {
          background-position: 100% 50%;
        }

        100% {
          background-position: 0% 0%;
        }
      }
    }
  }

  @media (max-width: grid.get-grid-bp('lg')) {
    >*:first-child {
      position: relative;
      z-index: 1;
    }

    >*:last-child {
      z-index: 0;
    }
  }
}
