@use '../custom-variables/colors' as *;

.header-section {
  padding-top: 4rem;
  padding-bottom: 0;
  padding-inline: unset;

  background: linear-gradient(to top, #A1C4FD, #C2E9FB);

  .page-header-text,
  .page-sub-header-text,
  .page-price-block {
    color: $header-page-title-and-price-color;
  }

  .page-header-text{
    margin: 0;
    margin-bottom: 0.7rem;
    line-height: 50px;
    font-weight: 600;

    @media (max-width: 768px) {
        line-height: 40px;
    }
  }

  .page-sub-header-text {
    color: $header-page-desc-text;
    &, *{
        color: $header-page-desc-text;
    }
    margin: 0;

    font-size: 20px;
    font-weight: 600;
    line-height: 1.3;

    @media (max-width: 768px) {
        font-size: 16px;
    }
  }

  &.waves-bg {
    background: url('/tools/img/bg/grid-1.svg') no-repeat, linear-gradient(0deg, #a1c4fd, #c2e9fb);
    background-position: 0 70px, 0;
    // background-size: 80% auto, contain; - origanal, was changed because was used only once
    background-size: 100% auto, contain;
  }
}
