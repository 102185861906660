.google-snipet-box {
    background-color: white;
    padding: 18px;

    .snipet-title {
      font-family: Arial, sans-serif;
      line-height: 1.3;
      padding-top: 4px;
      margin-bottom: 3px;
      font-size: 20px;
      color: #1A0DAB;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .snipet-meta {
      font-family: Arial, sans-serif;
      font-size: 14px;
      line-height: 1.58;
      color: #4d5156;

      .date {
        display: none;
      }
    }

    .snipet-url {
      font-family: Arial, sans-serif;
      font-size: 14px;
      color: #202124;
    }

    .schema-rating {
      background-repeat: repeat-x;
      display: none;
      overflow: hidden;
      position: relative;

      .google-stars {
        display: inline-block;
        background-repeat: repeat-x;
        position: relative;
        background-size: 13px 12px;
        height: 13px;
        top: 1px;
        width: 65px;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAmBAMAAABALxQTAAAAFVBMVEVMaXH4twP4twP4twP4twP4twP4twP7w8S/AAAAB3RSTlMAFv5uPpvQloUsTQAAAMFJREFUeAGE0TEOgzAMQFEXoDNiYC6/wFxxAsTADDkB5f6HqNRENXUi8TYiRfnY8lNXkjBOkuBWSeAhsYJOYiW9xO4MEqshkTbCSyIH7GLdgFasHHgmwkikZQD6OROZRG4Hxju8o/TNhbNhCqkOxaZDVKdxNnq/EjUS/A2o0PuXpyVeb9bjDWY9QSWXDQfBbtbjtWY9bM4sqfx+5yYt8wNcAFEzrGGkk5668KsFrKewPtQ3aFqh8WOnYZ+lIBQkgykAWk8rlAqcHfQAAAAASUVORK5CYII=);
      }

      .google-text {
        font-family: Arial, sans-serif;
        font-size: 14px;
        color: #70757a;
      }
    }

  }

  .google-snipet-box-mobile {
    background-color: white;
    padding: 16px;
    margin: 1em auto;
    border-radius: 8px;
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
    max-width: 400px;

    .snipet-title {
      font-family: 'Roboto', sans-serif;
      line-height: 1.3;
      padding-top: 4px;
      margin-bottom: 3px;
      font-size: 20px;
      color: #1A0DAB;
    }

    .snipet-meta {
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      line-height: 1.58;
      color: #4d5156;

      .date {
        display: none;
      }
    }

    .snipet-url {
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      color: #202124;
    }

    .schema-rating-mobile {
      background-repeat: repeat-x;
      display: none;
      overflow: hidden;
      position: relative;

      .google-stars {
        display: inline-block;
        background-repeat: repeat-x;
        position: relative;
        background-size: 13px 12px;
        height: 13px;
        top: 1px;
        width: 65px;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACoAAAAmBAMAAABALxQTAAAAFVBMVEVMaXH4twP4twP4twP4twP4twP4twP7w8S/AAAAB3RSTlMAFv5uPpvQloUsTQAAAMFJREFUeAGE0TEOgzAMQFEXoDNiYC6/wFxxAsTADDkB5f6HqNRENXUi8TYiRfnY8lNXkjBOkuBWSeAhsYJOYiW9xO4MEqshkTbCSyIH7GLdgFasHHgmwkikZQD6OROZRG4Hxju8o/TNhbNhCqkOxaZDVKdxNnq/EjUS/A2o0PuXpyVeb9bjDWY9QSWXDQfBbtbjtWY9bM4sqfx+5yYt8wNcAFEzrGGkk5668KsFrKewPtQ3aFqh8WOnYZ+lIBQkgykAWk8rlAqcHfQAAAAASUVORK5CYII=);
      }

      .google-text {
        font-family: Arial, sans-serif;
        font-size: 14px;
        color: #70757a;
      }

      .rating {
        display: block;
        font-family: Arial, sans-serif;
        font-size: 14px;
        color: #202124;
        padding-top: 1px;
        margin-bottom: -1px;
      }
    }
  }
