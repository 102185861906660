@use '../custom-variables/grid' as grid;
@use '../custom-variables/colors' as *;
@use 'cards';

.card-title {
  font-size: 1.45rem;
  font-weight: 500;
  line-height: 1.26;
  letter-spacing: -0.67px;

  @media (max-width: grid.get-grid-bp('lg')) {
    font-size: calc(1.063rem + 0.156vw);
  }
}

.card-subtitle {
  font-size: 0.89rem;
  font-weight: 400;
  line-height: 1.375;
  letter-spacing: -0.51px;
  color: $cards-gray;

  @media (max-width: grid.get-grid-bp('lg')) {
    font-size: calc(0.763rem + 0.156vw);
  }
}

.tool-promo-card {
  $transition-time: 500ms;
  @include cards.card-shadow;

  border-radius: 25px;
  background: $page-promo-card-bg;
  display: flex;
  flex-direction: column;

  text-decoration: none;
  color: inherit;
  text-align: center;

  min-width: 311px;
  width: 0%;

  aspect-ratio: 1/1;

  padding-block: 40px;
  padding-inline: 24px;

  transition: $transition-time;

  .card-header {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-bottom: 0.89rem;
    min-height: 55px;

    &,
    * {
      @extend .card-title;
    }
  }

  .card-body {
    .card-image-block {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 100px;
      aspect-ratio: 1/1;

      margin-bottom: 12px;
      margin-inline: auto;

      border-radius: 50%;
      background: $tool-promo-card-icon-bg;

      &,
      >* {
        transition: $transition-time;
      }

      $img-size: 57px;

      .image,
      img,
      svg {
        width: $img-size;
        height: $img-size;
      }

      i {
        font-size: $img-size;
        color: $tool-promo-card-icon-color
      }
    }
  }

  .card-desc {
    @extend .card-subtitle;
    margin-top: auto;

    &,
    * {}
  }

  &:hover {
    .card-image-block {
      background: $tool-promo-card-icon-bg-hover;

      i {
        color: $tool-promo-card-icon-color-hover;
      }
    }
  }

  @media (max-width: grid.get-grid-bp('lg')) {
    min-width: 250px;
    min-height: 250px;
    padding: 20px 0.75rem;

    .card-header {
      min-height: 40px;
    }
  }
}
