@use '../custom-variables/grid';

.step-by-step-section {
  .header {
    >* {
      margin: 0;
      float: right;
      padding-right: 20%;
    }

    margin-bottom: 5rem;
  }

  .content-block {
    display: flex;
    align-items: start;

    position: relative;
  }

  .person-block {
    position: absolute;
    bottom: 15%;
    z-index: 1;

    @media (max-width: grid.get-grid-bp('lg')) {
      display: none;
    }

    .speciality {
      color: unset;
    }
  }

  .img-block {
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;

    &.phone-square {
      video {
        border-radius: 50px;
      }

      &::after {
        content: '';
        width: 100%;
        height: 596px;
        position: absolute;

        background: url('/tools/img/phone-square.webp') no-repeat;
        background-size: contain;
        background-position: center;
        z-index: 1;
      }
    }

    &::before {
      content: '';
      position: absolute;
      background: url("/tools/img/bg/people-bg.svg") no-repeat;
      background-size: contain;
      width: 495px;
      height: 548px;
    }
  }

  .benefits-block {
    display: flex;
    flex-direction: column;


    .benefit-block {
      &:not(:last-child) {
        margin-bottom: 4rem;
      }

      &:nth-child(1) {
        align-self: end;
      }

      &:nth-child(2) {
        align-self: center;
      }

      &:nth-child(3) {
        align-self: start;
      }
    }
  }

  .benefit-block {
    display: flex;
    align-items: center;
    justify-content: center;

    max-width: 666px;

    .svg-block {
      margin-right: 30px;
    }

    .benefit-texts {
      .title {
        font-weight: 600;
        font-size: 32px;
        line-height: 30px;
        letter-spacing: -0.88px;

        margin-bottom: 1.5rem;
      }

      .text {
        font-weight: 400;
        font-size: 20px;
        line-height: 26px;


        a {
          color: inherit;
        }
      }
    }
  }

  @media (max-width: grid.get-grid-bp('lg')) {
    .header {
      >* {
        float: unset;
        padding-right: 0;
        text-align: center;
      }
    }

    .img-block {
      display: none;
    }

    .benefits-block {
      .benefit-block {

        &:nth-child(1),
        &:nth-child(2) {
          align-self: start;
        }
      }
    }
  }

  @media (max-width: grid.get-grid-bp('sm')) {
    .benefit-block {
      flex-direction: column;

      .svg-block {
        margin-right: 0;
      }

      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }

      .benefit-texts {
        text-align: center;

        .title {
          margin: 0;
        }
      }
    }
  }
}
