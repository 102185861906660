// grid
@import './custom-variables/grid.scss';

// colors
@import './custom-variables/colors.scss';

// buttons
@import './custom-variables/button-variables';

// fonts
$font-family-sans-serif: 'Noto Sans', sans-serif;

$font-size-root: 18px;
$font-size-base: 1rem;

$h1-font-size: $font-size-base * 2.88;
$h2-font-size: $font-size-base * 2.27;
$h3-font-size: $font-size-base * 1.77;
$h4-font-size: $font-size-base * 1.38;
$h5-font-size: $font-size-base * 1.11;
$h6-font-size: $font-size-base;

$headings-font-weight: 600;

$dropdown-border-radius: 20px;
$dropdown-border-color: transperent;

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  'block-1': 90px,
  'block-2': 144px,
);

$font-sizes: (
1: $h1-font-size,
2: $h2-font-size,
3: $h3-font-size,
4: $h4-font-size,
5: $h5-font-size,
6: $h6-font-size,
16: 0.888888rem) !default;

$offcanvas-backdrop-bg: none;
$offcanvas-padding-y: 22px;
$offcanvas-padding-x: 26px;

$spinner-width: 1.5rem;
$spinner-height: $spinner-width;
$spinner-vertical-align: -0.4em;

$badge-font-size: .77777rem;
$badge-padding-y: .27777rem;
$badge-padding-x: .5rem;
